<template>

  <DefaultCrudView
    @switch-inactive="switchInactive"
    @create-new="newRepository"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    :inactiveFilterValue="inactiveFilterValue"
    showInactiveFilter
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="repositories"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :item-class="itemRowStyle"
        :footer-props="$defaultDataTableFooterProps"
      >

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="editRepository(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                v-if="item.online !== null"
                :color="item.online ? 'success' : 'error'"
                dot
                bordered
                offset-x="10"
                offset-y="10"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click.stop="verifyGdriveConfig(item.repository_id)"
                >
                  mdi-google-drive
                </v-icon>
              </v-badge>

              <v-icon
                v-else
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="configureGdrive(item)"
              >
                mdi-google-drive
              </v-icon>

            </template>
            <span>{{ item.online === null ? $t('Configure GDrive') : $t('Verify GDrive config') }}</span>
          </v-tooltip>
          
        </template>
      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" @submit="save">
        <template v-slot:content>
          <DynamicFormContent
            :fields="fields"
            @file-server-change="handleSelectedFileServer"
          ></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('Google drive config')"
        ref="gdriveModal"
        :hide-save-button="true"
        :loading="loadingGdriveConfig"
        @modal-close="gDriveConfig = {}; loadData()"
      >
        <template v-slot:content>
          <v-simple-table style="height:200px">
            <template v-slot:default>
              <tbody v-if="!loadingGdriveConfig">
                <tr>
                  <th class="text-left">{{ $t('Document ID') }}</th>
                  <td>{{ gDriveConfig.document_id }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t('Created at') }}</th>
                  <td>{{ gDriveConfig.created_at }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t('Owner email') }}:</th>
                  <td>{{ gDriveConfig.owner_email }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t('Status') }}:</th>
                  <td>{{ (gDriveConfig.online ? 'online' : 'offline') }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }
</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'
import AppActions from '@/store/app/actions-types'
import TenantActions from '@/store/core/tenant/actions-types'
import FileServerActions from '@/store/content/file_server/actions-types'
import RepositoryActions from '@/store/content/repository/actions-types'
import RepositoryMutations from '@/store/content/repository/mutations-types'

export default {
  name: "Repository",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent,
  },

  data() {
    return {
      loading: true,
      options: {
        sortBy: ['title'],
        sortDesc: [false],
      },
      itemsLength: 0,
      headers: [
        {text: 'Title', value: 'title'},
        {text: 'File Server', value: 'file_server_label'},
        {text: 'Folder Name', value: 'name'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      actions: [
        {
          text: "create_repository",
          eventToEmit: "create-new",
        },
      ],
      gDriveConfig: {},
      loadingGdriveConfig: false
    }
  },

  mounted() {
    this.loading = true
    this[RepositoryMutations.SET_SELECTED_REPOSITORY](null)

    this[TenantActions.GET_ALL_TENANTS]()
      .then(() => {
        this[FileServerActions.GET_ALL_FILE_SERVERS]()
          .then((fileServers) => {
            this[RepositoryMutations.SET_FIELD_FILE_SERVERS](fileServers.items)

            this.reloadData()
          })
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('app', [
      'getIconForContentSettingsItem'
    ]),

    ...mapState({
      fields: state => state.content.repository.fields,
      tenants: state => state.core.tenant.tenants,
      fileServers: state => state.content.file_server.fileServers,
      repositories: state => state.content.repository.repositories,
      selectedRepository: state => state.content.repository.selectedRepository,
      inactiveFilterValue: state => state.content.repository.inactiveFilterValue,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('content_settings'),
          prependIcon: 'mdi-cog-outline',
          iconColor: 'primary',
          to: {
            name: "ContentSettings",
            params: { appId: this.$route.params.appId }
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.$t('repositories'),
        },
      ]
    }
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('content/file_server', [
      FileServerActions.GET_ALL_FILE_SERVERS,
    ]),

    ...mapActions('content/repository', [
      RepositoryActions.GET_ADMIN_REPOSITORIES,
      RepositoryActions.SAVE_REPOSITORY,
      RepositoryActions.SAVE_GDRIVE_CONFIG,
      RepositoryActions.GET_GDRIVE_CONFIG
    ]),

    ...mapMutations('content/repository', [
      RepositoryMutations.SET_SELECTED_REPOSITORY,
      RepositoryMutations.SET_FIELD_FILE_SERVERS,
      RepositoryMutations.SET_FIELD_TENANTS,
      RepositoryMutations.SET_INACTIVE_FILTER_VAL
    ]),

    newRepository() {
      this[RepositoryMutations.SET_SELECTED_REPOSITORY](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this[RepositoryActions.SAVE_REPOSITORY]()
        .then(() => {
          let message = this.selectedRepository ? 'repository_edited' : 'repository_created'
          
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))

          this.$refs.modal.closeModal()

          this.reloadData()
        })
        .catch(() => {
          this.$refs.modal.submitting = false
        })
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      if (!this.inactiveFilterValue) {
        options = {
          ...options,
          'filter[active]' : "true"
        }
      }

      this.loading = true
      this[RepositoryActions.GET_ADMIN_REPOSITORIES](options)
        .then((result) => {
          this.itemsLength = result.total
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    switchInactive() {
      this[RepositoryMutations.SET_INACTIVE_FILTER_VAL](!this.inactiveFilterValue)
      this.reloadData()
    },

    editRepository(repository) {
      this[RepositoryMutations.SET_SELECTED_REPOSITORY](repository)
      this.$refs.modal.openModal()
    },

    getModalTitle() {
      return this.selectedRepository ? this.$t('edit_repository') : this.$t('new_repository')
    },

    itemRowStyle(item) {
      return item.active ? '': 'inactive-row'
    },

    handleSelectedFileServer(fileServerId, clearValue = true) {
      const fileServerTenants = this.fileServers.find(fs => fs.file_server_id == fileServerId).tenant_ids ?? []

      const filteredTenants = this.tenants.filter(t => fileServerTenants.includes(t.tenant_id)) ?? []

      this[RepositoryMutations.SET_FIELD_TENANTS]({
        tenants: filteredTenants,
        clearValue,
      })
    },

    configureGdrive(repository) {
      if (!this.$google.apiInitied) {
        this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Google is not initialized yet'))
        return
      }

      this.$google.login()
        .then(() => {
          this.$google.api.client.drive.about.get({'fields': 'user'})
            .then((response) => {
              this.createDriveRepositoryFolder(repository, response.result.user.emailAddress)
            })
            .catch((error) => {
              console.log(error)
              this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Unable to retrieve Drive user'))
            })
        })
        .catch((error) => {
          console.log(error)
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Unable to login on Google'))
        })
    },

    createDriveRepositoryFolder(repository, ownerEmail) {
      let fileMetadata = {
        'name': repository.name,
        'mimeType': 'application/vnd.google-apps.folder'
      }

      this.loading = true

      this.$google.api.client.drive.files.create({resource: fileMetadata, fields: 'id'})
        .then(response => this.setDriveUserPermission(repository, response.result.id, ownerEmail))
        .catch(() => {
          this.loading = false
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Unable to create Google Drive folder'))
        })
    },

    setDriveUserPermission(repository, documentId, ownerEmail) {
      this.$google.api.client.drive.permissions.create({
        fileId: documentId,
        role: 'writer',
        type: 'user',
        emailAddress: process.env.VUE_APP_GDOCS_SERVICE_ACCOUNT_EMAIL
      })
      .then(() => this.saveRepositoryGdriveConfig(repository.repository_id, documentId, ownerEmail))
      .catch(() => {
        this.loading = false
        this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Unable to set Google Drive folder permission'))
      })
    },

    saveRepositoryGdriveConfig(repositoryId, documentId, ownerEmail) {
      this[RepositoryActions.SAVE_GDRIVE_CONFIG]({repositoryId, documentId, ownerEmail})
        .then(() => {
          this.reloadData()
        })
        .catch(() => this.loading = false)
    },

    verifyGdriveConfig(repositoryId) {
      this.loadingGdriveConfig = true
      this.$refs.gdriveModal.openModal()

      this[RepositoryActions.GET_GDRIVE_CONFIG](repositoryId)
        .then((gDriveConfig) => {
          this.gDriveConfig = gDriveConfig
          this.loadingGdriveConfig = false
        })
        .catch(() => this.loadingGdriveConfig = false)
    }
  }
}

</script>
